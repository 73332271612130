.base pre::-webkit-scrollbar {
  -webkit-appearance: none;
  @apply h-2 w-0;
}

.base pre::-webkit-scrollbar-track {
  @apply rounded-full bg-grey-scale-mid/50;
}

.base pre::-webkit-scrollbar-thumb {
  @apply cursor-pointer rounded-full bg-grey-scale-mid;
}
