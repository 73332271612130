.skipLink {
  position: fixed;
  transform: translateX(-101%);
  background-color: white;
  z-index: 9999;
  left: 0;
  top: 0;
  padding: 20px;
  font-size: 1.25rem;

  &:focus {
    transform: translateX(0);
  }
}
