.greenLink {
  position: relative;

  & > div:first-child > span:first-child,
  & > span:first-child {
    display: none;
    position: absolute;
    top: 50%;
    left: initial;
    right: 0;
    width: 0;
    transform: translate(0, -50%);
    transition: width 300ms ease-in-out;

    @media (min-width: 1024px) {
      display: block;
      overflow: hidden;
      right: initial;
      left: 0;
    }

    &[data-icon="external"] {
      display: block;
    }

    svg {
      transition: transform 300ms ease-in-out;
      transform: translateX(75%);

      @media (min-width: 1024px) {
        transform: translateX(-100%);
      }
    }
  }

  & > div:first-child > span:last-child,
  & > span:last-child {
    padding-left: 0;
    transition: padding 300ms ease-in-out;
  }

  &:focus,
  &:hover {
    @media (min-width: 1024px) {
      & > div:first-child > span:first-child,
      & > span:first-child {
        width: 15px;

        svg {
          transform: translateX(0);
        }
      }

      & > div:first-child > span:last-child,
      & > span:last-child {
        padding-left: 20px;
      }
    }
  }
}
