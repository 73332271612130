.base :is(h2, h3, h4) {
  @apply text-base font-medium xl:text-lg;
}

.base p {
  @apply t-body-base-blog;
}

.base :is(ul, ol) {
  @apply !mb-0;
}

.base strong {
  @apply font-semibold;
}

.base code {
  @apply rounded
  border-[0.5px]
  border-solid
  border-grey-scale-gray
  bg-grey-scale-light-gray
  px-1.5
  text-green-dark;
}
