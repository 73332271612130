.base::-webkit-scrollbar {
  -webkit-appearance: none;
  @apply h-[3px] w-0;
}

.base::-webkit-scrollbar-track {
  @apply rounded-full bg-[#E9EEF0];
}

.base::-webkit-scrollbar-thumb {
  @apply cursor-pointer rounded-full bg-grey-scale-light-gray;
}

.base {
  @apply pb-2.5;
}

.base table {
  @apply w-full overflow-hidden rounded-2xl text-xs leading-5 md:text-sm md:leading-6;
}

.base :is(th, td) {
  @apply border-2 border-content-primary-on-dark p-3 text-content-primary-on-light md:p-4 xl:p-5;
}

.base :is(th, td) strong {
  @apply font-medium;
}

.base th {
  @apply bg-organic-darker text-left font-medium;
}

.base td {
  @apply bg-[#F2F2F2];
}

.base a {
  @apply text-inherit;
}
