/* TODO check if those corresponds to design

lets try use EM on margins/paddings to match size changes
*/

.wysiwygStyling {
  position: relative;
}

.wysiwygStyling p {
  @apply mb-[2em]
  block
  last:mb-0;
}

.wysiwygStyling a {
  text-decoration: underline;
  text-underline-offset: 2px;
}

.wysiwygStyling strong {
}

.wysiwygStyling h1,
.wysiwygStyling h2,
.wysiwygStyling h3,
.wysiwygStyling h4,
.wysiwygStyling h5,
.wysiwygStyling h6 {
  @screen md {
    margin-bottom: 2em;
  }
}

.wysiwygStyling h1 a,
.wysiwygStyling h2 a,
.wysiwygStyling h3 a,
.wysiwygStyling h4 a,
.wysiwygStyling h5 a,
.wysiwygStyling h6 a {
  text-decoration: none;
}

.wysiwygStyling code {
  @apply border-grey-scale-light-gray
  px-1.5
  py-0;

  color: #7c160d;
  border-width: 0.5px;
  border-style: solid;
  border-radius: 4px;
}

/** forbidden tags - make sure it stand out for content person */

.wysiwygStyling h1 {
  color: red;
}

.wysiwygStyling table {
  background-color: red;
}
