.labelItem {
  position: relative;

  & > span {
    pointer-events: none;
    display: inline-block;
    padding-right: 18px;
    position: relative;

    span {
      pointer-events: none;
      display: inline-block;
      width: 8px;
      height: 8px;
      position: absolute;
      top: 34%;
      right: 0;
      transition: opacity 300ms ease-in-out;
      opacity: 0.25;

      @media (min-width: 1024px) {
        top: 25%;
      }

      &:after,
      &:before {
        content: "";
        display: block;
        position: absolute;
        transition: all 300ms ease;
        background: #181818;
      }

      &::after {
        height: 100%;
        width: 2px;
        left: 50%;
        top: 0;
        transform: translate(-50%, 0);
      }

      &::before {
        height: 2px;
        width: 100%;
        top: 50%;
        left: 0;
        transform: translate(0, -50%);
      }
    }
  }

  &:hover,
  &[aria-expanded="true"] {
    &:after {
      width: calc(100% - 32px);
    }

    & > span {
      span {
        opacity: 1;

        &::after {
          left: 25%;
          top: 0;
          transform: translate(-100%, 0) rotate(-45deg);
          @apply bg-green-accent-two;
        }

        &::before {
          top: 40%;
          left: 10%;
          transform: translate(0, 0) rotate(-45deg);
          @apply bg-green-accent-two;
        }
      }
    }
  }

  &[data-color="white"] {
    & > span {
      span {
        &:after,
        &:before {
          @media (min-width: 1024px) {
            background-color: #f9f9f9;
          }
        }
      }
    }
    &:hover,
    &[aria-expanded="true"] {
      & > span {
        span {
          &:after,
          &:before {
            @apply bg-green-accent-two;
          }
        }
      }
    }
  }

  @media (min-width: 1024px) {
    & > span span {
      display: none;
    }
  }
}
