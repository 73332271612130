.scrollbar::-webkit-scrollbar {
  -webkit-appearance: none;
  @apply w-1.5;
}

.scrollbar::-webkit-scrollbar-thumb {
  @apply cursor-pointer rounded-md bg-grey-scale-light-gray;
}

.scrollbar::-webkit-scrollbar-thumb:hover {
  @apply bg-grey-faded;
}
