.lightGray {
  &:before {
    position: absolute;
    bottom: 0;
    left: 55.5%;
    width: 60%;
    height: 150%;

    @media (min-width: 1280px) {
      content: "";
      display: block;
      background: #e9eef0;
    }

    @media (min-width: 1536px) {
      left: 48.5%;
    }

    @media (min-width: 1720px) {
      left: 48.75%;
    }
  }
}
