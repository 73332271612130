.blackLink {
  text-decoration: none;

  & > span:first-child {
    padding-bottom: 2px;
    background: linear-gradient(to right, transparent, transparent),
      linear-gradient(to right, currentColor, currentColor);
    background-size:
      100% 1px,
      0 1px;
    background-position:
      100% 100%,
      0 100%;
    background-repeat: no-repeat;
    transition:
      background-size 200ms,
      color 100ms ease-in;
  }

  &:hover,
  &:focus {
    & > span:first-child {
      background-size:
        0 1px,
        100% 1px;
    }
  }
}
