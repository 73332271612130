@screen md {
  .gradient {
    background: radial-gradient(
      circle at 200%,
      theme("colors.tangerine-light") 85%,
      theme("colors.yellow-saturated") 85.2%
    );
  }
}

@screen xl {
  .gradient {
    background: radial-gradient(
      circle at right,
      theme("colors.tangerine-light") 80%,
      theme("colors.yellow-saturated") 80.2%
    );
  }
}
