.feturedLink {
  text-decoration: none;

  span {
    padding-bottom: 2px;
    background: linear-gradient(to right, transparent, transparent),
      linear-gradient(to right, currentColor, currentColor);
    background-size:
      100% 1px,
      0 1px;
    background-position:
      100% 100%,
      0 100%;
    background-repeat: no-repeat;
    transition:
      background-size 200ms,
      color 100ms ease-in;
  }

  &:hover,
  &:focus {
    span {
      background-size:
        0 1px,
        100% 1px;
    }
  }

  span {
    padding-bottom: 0;
    background-position:
      100% 80%,
      0 80%;
  }

  img {
    transition: transform 300ms ease;
  }

  &:hover {
    img {
      transform: scale(1.1);
    }
  }
}
